#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.container-body {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;
}
